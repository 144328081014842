<template>
  <div v-if="getBalance[currency] && getCurrency[currency]">
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>
      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <div class="md:py-8 lg:py-12 max-w-2xl w-full">
          <div class="p-4 mb-3 space-y-3">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>

          <div class="flex flex-col bg-gray-900 bg-opacity-30">
            <div
              class="flex flex-col sm:flex-row px-8 py-3 sm:py-6 border-theme-secondary-300 rounded-t-xl border-t-2 border-l-2 border-r-2"
            >
              <div class="flex flex-grow items-center py-3 sm:py-0">
                <img class="h-8 mr-2" :src="getCurrency[currency].logo" />
                <span
                  class="text-3xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-pink-400"
                >
                  {{ getCurrency[currency].name }}</span
                >
              </div>

              <div class="flex-none sm:mt-2">
                <div
                  class="inline text-xs px-2 py-1 mr-2 border rounded-full text-yellow-50 border-yellow-50 cursor-pointer opacity-80 hover:opacity-100 focus:opacity-100"
                >
                  <a
                    class="text-blue opacity-80 hover:opacity-100 focus:opacity-100 text-white"
                    v-bind:href="getCurrency[currency].website"
                    target="_blank"
                  >
                    <i class="fa fa-globe"></i> Website
                  </a>
                </div>

                <a
                  class="inline opacity-80 hover:opacity-100 focus:opacity-100"
                  v-bind:href="`
                    https://${explorer}/token/${getCurrency[currency].address[chain].address}?a=${getUser.address}`"
                  target="_blank"
                >
                  <img
                    class="inline-block h-5"
                    :src="require(`@/assets/chain/bscscan.png`)"
                  />
                </a>

                <div
                  class="inline text-xs px-2 py-1 ml-2 border rounded-full text-yellow-50 border-yellow-50 cursor-pointer opacity-80 hover:opacity-100 focus:opacity-100"
                  v-on:click="
                    addToken({
                      c: currency,
                      ca: getCurrency[currency].address[chain].address,
                      decimals: getCurrency[currency].address[chain].decimals,
                      logo: getCurrency[currency].logo,
                    })
                  "
                >
                  Add to
                  <img
                    src="@/assets/svg/metamask.svg"
                    class="inline-block ml-1 h-5"
                  />
                </div>
              </div>
            </div>

            <div class="py-3 sm:py-6 px-8 border-r-2 border-l-2 border-t">
              <div class="grid grid-cols-3 text-center">
                <div class="flex flex-col">
                  <div
                    class="flex flex-col flex-1 justify-between font-semibold space-y-2 p-1 -m-1"
                  >
                    <div class="text-sm leading-tight text-gray-500">
                      Total balance
                    </div>
                    <span class="leading-tight whitespace-nowrap">
                      <span class="text-xs sm:text-lg">
                        {{ getBalance[currency].available }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div
                    class="flex flex-col flex-1 justify-between font-semibold space-y-2 p-1 -m-1"
                  >
                    <div class="text-sm leading-tight text-gray-500">
                      In order
                    </div>
                    <span class="leading-tight whitespace-nowrap">
                      <span class="text-xs sm:text-lg">
                        {{ getBalance[currency].inorder }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div
                    class="flex flex-col flex-1 justify-between font-semibold space-y-2 p-1 -m-1"
                  >
                    <div class="text-sm leading-tight text-gray-500">
                      In staking
                    </div>
                    <span class="leading-tight whitespace-nowrap">
                      <span class="text-xs sm:text-lg">
                        {{ getBalance[currency].instaking }}
                      </span></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="px-8 py-3 sm:py-6 rounded-b-xl border-r-2 border-b-2 border-l-2 border-t"
            >
              <div v-if="getCurrency[currency].type == 'DISABLED'">
                Deposits and withdrawals are disabled.
              </div>
              <div v-if="lasttx" class="mb-6 space-y-3">
                Recent Transactions:
                <a
                  class="inline opacity-80 hover:opacity-100 focus:opacity-100"
                  v-bind:href="`https://${explorer}/tx/${lasttx}`"
                  target="_blank"
                  v-if="chain == 'bsc'"
                >
                  <img
                    class="inline-block h-5 mr-2"
                    :src="require(`@/assets/chain/bscscan.png`)"
                  />
                  <span>{{ lasttx.slice(0, 6) }}...{{ lasttx.slice(-4) }}</span>
                </a>
              </div>

              <div v-if="getCurrency[currency].address[chain]">
                <div class="flex flex-col">
                  <div
                    v-if="
                      getCurrency[currency].type == 'ENABLED' ||
                      getCurrency[currency].type == 'DEPOSIT_ONLY'
                    "
                  >
                    <div class="flex font-semibold mt-2">
                      <span
                        class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                      >
                        Available Deposit
                      </span>
                      <span
                        class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base cursor-pointer hover:text-yellow-200"
                      >
                        <span v-on:click="deposit_amount = tokenBalance">
                          {{ tokenBalance }} {{ currency }}</span
                        >
                      </span>
                    </div>
                    <form @submit.prevent="deposit" class="mt-2">
                      <div
                        class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                      >
                        <input
                          id="amount"
                          v-model="deposit_amount"
                          placeholder="0"
                          class="flex-grow w-full outline-none text-lg bg-transparent"
                          type="number"
                          min="0"
                          step="0.00000001"
                        />

                        <button
                          v-if="loading"
                          class="flex-none text-sm px-2 py-2 border rounded text-gray-400 border-gray-400 cursor-wait"
                          disabled
                        >
                          <i class="fad fa-spinner-third fa-spin m-r-xs"></i>
                          Loading
                        </button>
                        <button
                          v-else-if="!deposit_amount"
                          class="w-32 flex-none text-sm px-6 py-2 rounded text-gray-400 cursor-not-allowed"
                          disabled
                        >
                          Deposit
                        </button>
                        <button
                          v-else
                          class="w-32 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-pink-600 to-yellow-600"
                        >
                          Deposit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="grid grid-cols-1 gap-y-3">
                  <div class="flex flex-col space-y-8 space-y-0">
                    <div
                      v-if="
                        getCurrency[currency].type == 'ENABLED' ||
                        getCurrency[currency].type == 'WITHDRAW_ONLY'
                      "
                    >
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                        >
                          Available Withdrawal
                        </span>
                        <span
                          class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base cursor-pointer hover:text-yellow-200"
                        >
                          <span
                            v-on:click="
                              withdrawal_amount = getBalance[currency].available
                            "
                          >
                            {{ getBalance[currency].available }}
                            {{ currency }}</span
                          >
                        </span>
                      </div>
                      <form @submit.prevent="withdrawal" class="mt-2">
                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                        >
                          <input
                            id="amount"
                            v-model="withdrawal_amount"
                            placeholder="0"
                            class="flex-grow w-full outline-none text-lg bg-transparent"
                            type="number"
                            min="0"
                            step="0.00000001"
                          />

                          <button
                            v-if="loading"
                            class="flex-none text-sm px-2 py-2 border rounded text-gray-400 border-gray-400 cursor-wait"
                          >
                            <i class="fad fa-spinner-third fa-spin m-r-xs"></i>
                            Loading
                          </button>
                          <button
                            v-else-if="!withdrawal_amount"
                            class="w-32 flex-none text-sm px-6 py-2 border rounded text-gray-400 border-none border-gray-400 cursor-not-allowed"
                            disabled
                          >
                            Withdrawal
                          </button>
                          <button
                            v-else
                            class="w-32 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-pink-600 to-yellow-600"
                          >
                            Withdrawal
                          </button>
                        </div>
                      </form>

                      <div class="text-gray-500 mt-2 text-xs sm:text-base">
                        Network Fee
                      </div>
                      <span
                        class="flex flex-col sm:flex-row p-2 border rounded-lg mb-2 border-opacity-30 text-xs sm:text-base"
                        ><span class="flex-grow"
                          ><img
                            class="inline-block h-5 mr-2"
                            :src="require(`@/assets/chain/${chain}.png`)"
                          />
                          {{ chain_full }} </span
                        ><span class="flex-none text-gray-400"
                          >Fee :
                          {{
                            getCurrency[currency].address[chain].withdraw_fee
                          }}
                          {{ currency }}</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LiveTx />
        </div>
      </div>
      <footer>
        <StickFooter />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import func from "@/widgets/func";
import user from "@/api/user";
import LiveTx from "@/components/LiveTx.vue";
import {
  POOL_ADDRESS,
  SITE_URL,
  CHAIN,
  EXPLORER,
  CHAIN_FULL_NAME,
} from "../../config";
import nativeToast from "native-toast";

import Web3 from "web3";

import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";

const tokenABI = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],

    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    type: "function",
  },
];

export default {
  components: {
    ConnectWallet,
    StickFooter,
    LiveTx,
  },
  data() {
    return {
      chain: CHAIN.toLowerCase(),
      explorer: EXPLORER,
      chain_full: CHAIN_FULL_NAME,
      currency: this.$route.params.currency,
      accounts: [],
      deposit_amount: 0,
      withdrawal_amount: 0,
      loading: false,
      value: null,
      dara: null,
      to: null,
      contract_address: null,
      tokenBalance: null,
      lasttx: null,
    };
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),
  methods: {
    async withdrawal() {
      this.loading = true;

      if (
        this.amount -
          this.getCurrency[this.currency].address[this.chain].withdraw_fee / 1 <
        0
      ) {
        this.loading = false;
        nativeToast({
          message: `Minimum withdrawal: ${
            (this.getCurrency[this.currency].address[this.chain].withdraw_fee *
              2) /
            1
          } ${this.currency}`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
        return;
      }

      await user
        .withdraw({
          amount: this.withdrawal_amount,
          currency: this.currency,
          chain: this.chain,
        })
        .then((response) => {
          this.loading = false;
          nativeToast({
            message: response.data.message,
            position: "top",
            timeout: 10000,
            type: "success",
          });
          this.withdrawal_amount = null;
        })
        .catch((error) => {
          this.loading = false;
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
    deposit() {
      this.loading = true;
      if (this.tokenBalance / 1 < this.deposit_amount / 1) {
        this.loading = false;
        nativeToast({
          message: `The amount you entered is more than your available balance.( You have ${this.tokenBalance} ${this.currency})`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
        return;
      }
      const web3 = new Web3(ethereum);

      if (
        (this.currency == "BNB" && this.chain == "bsc") ||
        (this.currency == "ETH" && this.chain == "eth") ||
        (this.currency == "MATIC" && this.chain == "polygon")
      ) {
        this.to = POOL_ADDRESS; // pool address
        this.value =
          "0x" + (this.deposit_amount * Math.pow(10, 18)).toString(16);
        this.data = "0x";
      } else {
        var contract = new web3.eth.Contract(tokenABI, this.contract_address);
        this.to = this.contract_address; //contract address
        this.value = "0x0";
        this.data = contract.methods
          .transfer(
            POOL_ADDRESS,
            (this.deposit_amount * 1000000000000000000).toLocaleString(
              "fullwide",
              { useGrouping: false }
            )
          )
          .encodeABI();
      }

      //Sending Ethereum to an address
      ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: this.accounts[0],
              to: this.to,
              value: this.value,
              data: this.data,
            },
          ],
        })
        .then((txHash) => {
          this.loading = false;
          this.lasttx = txHash;
          this.getTokenBalance();

          setTimeout(() => {
            this.getTokenBalance();
          }, 3500);

          nativeToast({
            message: `Tx Submitted: ${txHash.slice(0, 12)}...${txHash.slice(
              -10
            )}`,
            position: "top",
            timeout: 3000,
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          nativeToast({
            message: error.message,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
    async getAccount() {
      this.accounts = await ethereum.request({ method: "eth_requestAccounts" });
      this.getTokenBalance();
    },
    async getTokenBalance() {
      const web3 = new Web3(ethereum);

      if (
        (this.currency == "BNB" && this.chain == "bsc") ||
        (this.currency == "ETH" && this.chain == "eth") ||
        (this.currency == "MATIC" && this.chain == "polygon")
      ) {
        const ether_raw = await web3.eth.getBalance(this.accounts[0]);
        this.tokenBalance = (ether_raw / Math.pow(10, 18)).toFixed(8);
      } else {
        const tokenInst = new web3.eth.Contract(
          tokenABI,
          this.contract_address
        );
        const token_raw = await tokenInst.methods
          .balanceOf(this.accounts[0])
          .call();
        this.tokenBalance = (token_raw / Math.pow(10, 18)).toFixed(8);
      }
    },
    amount(a) {
      return func.fixed8(a);
    },
    async addToken(body) {
      const tokenAddress = body.ca;
      const tokenSymbol = body.c;
      const tokenDecimals = body.decimals;
      if (!body.logo) body.logo = `/tokens/BLANK.png`;
      const tokenImage = SITE_URL + body.logo;

      try {
        const wasAdded = await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });
      } catch (error) {
        nativeToast({
          message: error.message,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
    },
  },
  created() {
    if (this.getCurrency[this.currency].address[this.chain]) {
      this.getAccount();
      this.contract_address =
        this.getCurrency[this.currency].address[this.chain].address;
    }
  },
  updated() {
    this.getTokenBalance();
  },
};
</script>
